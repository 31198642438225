<template>
  <v-sheet>
    <component :is="currentRole" />
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
export default {
  name: "Absent",
  metaInfo: {
    title: i18n.t("routes.absent"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    AbsentAdmin: () => import("./AbsentAdmin"),
    AbsentTeacher: () => import("./AbsentTeacher"),
    AbsentStudent: () => import("./AbsentStudent")
  },
  computed: mapGetters(["g_privilages_user", "g_role_type"]),
  created() {
    const showTab = [];
    this.tabCode.map(d => {
      showTab.push(this.g_privilages_user.includes(d));
    });
    if (this.g_role_type === "STUDENT") {
      this.currentRole = "AbsentStudent";
    } else {
      if (showTab.includes(true)) {
        this.currentRole = "AbsentAdmin";
      } else {
        this.currentRole = "AbsentTeacher";
      }
    }
  },
  data() {
    return {
      currentRole: "AbsentAdmin",
      tabCode: ["AAS01", "AAS01", "AAS03"]
    };
  }
};
</script>
